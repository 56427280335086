import BaseBean from "@/utils/BaseBean";

export interface ITtPayCardDataObj {
    utilInst:TtPayCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class TtPayCardUtil extends BaseBean{
    public dataObj:ITtPayCardDataObj;

    constructor(proxy:any,dataObj:ITtPayCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.customerAmountData=await this.utils.OrderProviderApi.loadCanPay({params:{from:'ttPay'}});//查询可以用于支付的客户账户
    }
    //提交
    public async doSubmit():Promise<any>{
        this.utils.Tools.configBox({
            message:'提交之后将不能修改单据，确定提交吗?',
            sureFn:async ()=> {
                this.dataObj.form.saveType='submit';
                await this.proxy.engine.saveHandler();
                // let res=await this.utils.OrderProviderApi.ttPaySubmit({params:{id:this.dataObj.form.id}});
                // if(res.result){
                //     this.utils.Tools.success({message:'操作成功'});
                //     await this.proxy.engine.engineUtil.doAddOrLoad(this.dataObj.form.id,'card');
                // }
            }
        });
    }
    //构建信保回款的时候，支付方式下拉数据
    public buildXbhkPayType(params:any):any{
        let data:Array<any>=[];

        if(params.row.F_ID){//修改
            let payType=params.row._F_PAY_TYPE;
            switch (payType) {
                case 0://如果现在是预付款，分两种情况，尾款已冲销，那么就只能是预付款了，如果尾款还未冲销，那么就可以选预付款、尾款和全款
                    params.row.F_WK_CX_FLAG==1?data=[{value:0,label:'预付款'},{value:1,label:'尾款'},{value:2,label:'全款'}]:data=[{value:0,label:'预付款'}];break;
                case 1://如果现在是尾款，分两种情况，预付款已冲销，那么只能是尾款了，如果预付款还未冲销，那么就可以选预付款、尾款和全款
                    params.row.F_YFK_CX_FLAG==1?data=[{value:0,label:'预付款'},{value:1,label:'尾款'},{value:2,label:'全款'}]:data=[{value:1,label:'尾款'}];break;
                case 2://如果现在是全款，那么就可以选预付款、尾款和全款,因为可以改变为任何一种冲销
                    data=[{value:0,label:'预付款'},{value:1,label:'尾款'},{value:2,label:'全款'}];break;
            }
        }else{//新增
            if(params.row.F_YFK_CX_FLAG==1)data.push({value:0,label:'预付款'});//没有付过预付款
            if(params.row.F_WK_CX_FLAG==1) data.push({value:1,label:'尾款'});//没有付过尾款
            if(params.row.F_YFK_CX_FLAG==1 && params.row.F_WK_CX_FLAG==1)data.push({value:2,label:'全款'});
        }
        return data;
    }
}