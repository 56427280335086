import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import ZxbCardUtil,{IZxbCardDataObj} from './zxbCardUtil';
import OaApplyCard from "@/views/project/order/finance/oa/oaApply/OaApplyCard.vue";
export default defineComponent({
    name:'ZxbCard',
    title:'中信保账户',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IZxbCardDataObj=reactive<IZxbCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                saveConfirm: true,
                saveConfirmContent:"保存之后不能修改<font color='red'>中信保代码、客户、币种</font>",
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/zxb')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                code: [utils.UtilPub.commonValidRule(proxy.$t('zxb.form.code_rule'))],
                customerId: [utils.UtilPub.commonValidRule(proxy.$t('zxb.form.customerId_rule'))],
                amount: [utils.UtilPub.commonValidRule(proxy.$t('zxb.form.amount_rule'))],
                day: [utils.UtilPub.commonValidRule(proxy.$t('zxb.form.day_rule'))],
                pfbl: [utils.UtilPub.commonValidRule(proxy.$t('zxb.form.pfbl_rule'))],
                bz: [utils.UtilPub.commonValidRule(proxy.$t('zxb.form.bz_rule'))],
            },
            otherParams:{
                showHis:false,//是否显示oa天数、使用限额、赔付比例的变动历史
                isLoad:false,//编辑的时候不能修改中信保代码、币种、客户
                unit:'元',
                customerData:[],//客户下拉数据
                activeName:'baseInfo',
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ZxbCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
                if(params && 'bz'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('customerId'==selectId){}
            if('bz'==selectId){
                dataObj.otherParams.unit=newValue=='0'?'元':'$'
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            dataObj.otherParams.unit=res.data.bz==0?'元':'$';
            if('/load'==addOrLoad){
                dataObj.otherParams.isLoad=true;
                dataObj.otherParams.showHis=true;
                engineInst.otherParams.compParams.saveConfirm=false;

                //账户变动信息
                res.data.changeBeanList.sort((item1:any,item2:any)=>{
                    return item1.time.localeCompare(item2.time);
                });
            }
        }
        //传入参数到后台
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        //查看支付的信用证详情
        const xyzDetail=async (item:any)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:item.billId,modelComp:OaApplyCard,ownerComp:proxy,cardFrom:'zxbCard',
                title:'支付详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,setParam,beforeSaveHandler,xyzDetail
         
        }
    }
});