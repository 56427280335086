import BaseBean from "@/utils/BaseBean";
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
import XyzCard from "@/views/project/order/finance/xyz/xyz/XyzCard.vue";
import OaApplyCard from "@/views/project/order/finance/oa/oaApply/OaApplyCard.vue";
import TtPayCard from "@/views/project/order/finance/tt/ttPay/TtPayCard.vue";
export interface ISkfsListDataObj {
    utilInst:SkfsListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class SkfsListUtil extends BaseBean{
    public dataObj:ISkfsListDataObj

    constructor(proxy:any,dataObj:ISkfsListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});//查询所有客户
    }
    //查看pi弹出框
    public async piDetail(piId:string):Promise<any>{
        await this.utils.UtilPub.openDialog({proxy:this.proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:this.proxy,cardFrom:'SkqrList',
            title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
    }
    //查看支付详情弹出框
    public async payDetail(row:any,type:number):Promise<any>{
        let id=row.F_YFK_PAY_ID;
        let payType=row._F_YFK_PAY_TYPE;//支付方式
        let modelComp;//打开的弹出框是TT、信用证、OA
        if(1==type) {//尾款
            id=row.F_WK_PAY_ID;
            payType=row._F_WK_PAY_TYPE;
        }
        switch (payType) {
            case 0://TT
                let res1=await this.utils.OrderProviderApi.getTtPayIdByDetailId({params:{id:id}});
                id=res1.id;
                modelComp=TtPayCard; break;
            case 1://信用证
                let res2=await this.utils.OrderProviderApi.getXyzIdByDetailId({params:{id:id}});
                id=res2.id;
                modelComp=XyzCard; break;
            case 2://OA
                let res3=await this.utils.OrderProviderApi.getOaApplyIdByDetailId({params:{id:id}});
                id=res3.id;
                modelComp=OaApplyCard;break;
                break;
        }
        await this.utils.UtilPub.openDialog({proxy:this.proxy,addOrEdit:'edit',id:id,modelComp:modelComp,ownerComp:this.proxy,cardFrom:'SkqrList',
            title:'支付详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
    }
    //收款确认
    public async skfsHandler(type:number):Promise<any>{
        let selections:Array<any>=this.dataObj.pageListRef.getTbInst().getSelection();
        if(selections.length==0){
            this.proxy.$message('请选择要操作的行');
            return ;
        }

        if(0==type){//预付款
            if(selections[0]['F_YFK_QR_FLAG']==3){
                this.proxy.$message({type:'warning',message:'预付款已复审'});return;
            }
            if(selections[0]['F_YFK_QR_FLAG']!=2){
                this.proxy.$message({type:'warning',message:'还不能复审预付款'});return;
            }
        }else{//尾款
            if(selections[0]['F_WK_QR_FLAG']==3){
                this.proxy.$message({type:'warning',message:'尾款已复审'});return;
            }
            if(selections[0]['F_WK_QR_FLAG']!=2){
                this.proxy.$message({type:'warning',message:'还不能复审尾款'});return;
            }
        }
        this.dataObj.otherParams.showPayAllFlag=selections[0].F_PAY_ALL_FLAG==0?false:true;
        this.dataObj.otherParams.type=type;
        if(this.dataObj.otherParams.showPayAllFlag){
            this.dataObj.otherParams.dialogTitle='【预付款、尾款】复审';
        }else{
            this.dataObj.otherParams.dialogTitle=type==0?'【预付款】复审':'【尾款】复审';
        }
        this.dataObj.otherParams.selInfo.F_PI_ID=selections[0].F_PI_ID;
        this.dataObj.otherParams.selInfo.F_ORDER_CODE=selections[0].F_ORDER_CODE;
        if(this.dataObj.otherParams.showPayAllFlag){
            this.dataObj.otherParams.selInfo.F_AMOUNT=selections[0].F_TOTAL_AMOUNT;
        }else{
            this.dataObj.otherParams.selInfo.F_AMOUNT=this.dataObj.otherParams.type==0?selections[0].F_YFK:selections[0].F_WK;
        }
        this.dataObj.otherParams.dialogVisible=true;
    }
    //收款确认发起请求处理
    public async doShfs():Promise<any>{
        let selections:Array<any>=this.dataObj.pageListRef.getTbInst().getSelection();
        let params={id:selections[0].F_ID,type:this.dataObj.otherParams.type,shType:this.dataObj.otherParams.shType};
        let res=await this.utils.OrderProviderApi.skfsAction({params:params});
        if(res.result){
            this.dataObj.pageListRef.queryHandler(true);
            this.utils.Tools.success();
            this.dataObj.otherParams.dialogVisible=false;
        }
    }
}