import BaseBean from "@/utils/BaseBean";
import _ from "lodash";

export interface IOaApplyCardDataObj {
    utilInst:OaApplyCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class OaApplyCardUtil extends BaseBean{
    public dataObj:IOaApplyCardDataObj;

    constructor(proxy:any,dataObj:IOaApplyCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建中信保账户下拉数据
        this.dataObj.otherParams.oaZxbData=await this.utils.OrderProviderApi.getAllOaZxb({});
    }
    //提交
    public async doSubmit():Promise<any>{
        this.utils.Tools.configBox({
            message:'提交之后将不能修改单据，确定提交吗?',
            sureFn:async ()=> {
                this.dataObj.form.saveType='submit';
                await this.proxy.engine.saveHandler();
                // let res=await this.utils.OrderProviderApi.oaApplySubmit({params:{id:this.dataObj.form.id}});
                // if(res.result){
                //     this.utils.Tools.success({message:'操作成功'});
                //     await this.proxy.engine.engineUtil.doAddOrLoad(this.dataObj.form.id,'card');
                // }
            }
        });
    }
}