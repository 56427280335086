import BaseBean from "@/utils/BaseBean";

export interface IZxbCardDataObj {
    utilInst:ZxbCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class ZxbCardUtil extends BaseBean{
    public dataObj:IZxbCardDataObj;

    constructor(proxy:any,dataObj:IZxbCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});//查询所有客户
    }
}