import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import TtPayCardUtil,{ITtPayCardDataObj} from './ttPayCardUtil';
import FinanceUtils from "@/views/project/order/finance/financeUtils";
import FormalOrderList from "@/views/project/order/order/formalOrder/FormalOrderList.vue";
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
export default defineComponent({
    name:'TtPayCard',
    title:'TT支付',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:ITtPayCardDataObj=reactive<ITtPayCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: true,
                detailInfos:{
                    ttPayDetail:{},
                },
                details:['ttPayDetail'],
                modelPath: utils.OrderProviderApi.buildUrl('/ttPay')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                customerAmountId: [utils.UtilPub.commonValidRule(proxy.$t('ttPay.form.customerAmountId_rule'))],
                type: [utils.UtilPub.commonValidRule(proxy.$t('ttPay.form.type_rule'))]
            },
            otherParams:{
                cardForm:'',
                customerAmountData:[],//客户
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TtPayCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerAmountId'==params.comboId){
                    return dataObj.otherParams.customerAmountData;
                }
                if(params && 'type'==params.comboId){
                    return [{value:0,label:'订单收款'},{value:1,label:'信保回款'}]
                }
                if(params && 'F_PAY_TYPE'==params.comboId){
                    if(dataObj.form.type==0){
                        return FinanceUtils.buildPayTypeData(params);
                    }else if(dataObj.form.type==1){
                        return dataObj.utilInst.buildXbhkPayType(params);
                    }
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:any,selectId:string)=>{
            if('customerAmountId'==selectId){
                dataObj.refMap.get('ttPayDetail').clearDetailData();
                if(newValue){
                    dataObj.form.customerId=newValue.item.customerId;
                    dataObj.form.bz=newValue.item.bz;
                    dataObj.form.balance=newValue.item.amount;
                }else{
                    dataObj.form.customerId='';
                    dataObj.form.bz='';
                    dataObj.form.balance=0;
                }
            }
            if('type'==selectId){
                dataObj.refMap.get('ttPayDetail').clearDetailData();
            }
            if('F_PAY_TYPE'==selectId){
                //付款类型改变了，重新结算整个信用证要用到的金额
                let existRows=dataObj.refMap.get('ttPayDetail').getTbInst().tbData;
                dataObj.form.amount=FinanceUtils.gridOrderTotalAmount(existRows);
            }
        }
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        gridTitle:'TT支付明细',
                        readOnly:dataObj.disabled,
                        toolBar: [{
                            id: "chooseOrder",
                            title: "选择订单",
                            type: "primary",
                            icon: "iconfont icon-chaxun",
                            clickEvent: 'chooseOrder',
                            param:{}
                        }],
                        canAdd: false,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: utils.OrderProviderApi.buildUrl('/ttPay/detail?t='+Math.random()*1000)
                    }
                }
            }
        })
        //计算应收预付款、尾款、订单总金额
        const payMoney=computed(()=>{
            return (params:any) => {
                return FinanceUtils.calAmount(params);
            }
        })

        //选择订单按钮事件
        const chooseOrder=async ()=>{
            if(!dataObj.form.customerAmountId){
                proxy.$message('请先选择中信保账户');
                return;
            }
            await utils.UtilPub.openDialog({customerId:dataObj.form.customerId,bz:dataObj.form.bz,type:dataObj.form.type,
                proxy:proxy,modelComp:FormalOrderList,ownerComp:proxy,
                from:'ttPay',overflow:'hidden',title: '选择订单',contentHeight:'',showFooterBtn:true,fullscreen:true})
        }
        //选择订单弹出框确定事件
        const sureHandler=async (engineRef:any)=>{
            let selectRows=engineRef.pageListRef.getTbInst().getSelection();
            FinanceUtils.appendSelOrder(selectRows,dataObj,'ttPayDetail');
            return true;
        }
        //查看pi弹出框
        const piDetail=async (piId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:proxy,cardFrom:'oaApplyCard',
                title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            if('/load'==addOrLoad){
                await nextTick(()=>{
                    dataObj.form.customerAmountId=dataObj.otherParams.customerAmountData.find((item:any)=>item.value==dataObj.form.customerAmountId);
                    if(dataObj.form.customerAmountId){
                        dataObj.form.customerId=dataObj.form.customerAmountId.item.customerId;
                        dataObj.form.bz=dataObj.form.customerAmountId.item.bz;
                        dataObj.form.balance=dataObj.form.customerAmountId.item.amount;
                    }
                })
            }
            if(res.data.flag==1){//已提交，页面禁用
                dataObj.disabled=true;
                dataObj.refMap.get('ttPayDetail').setReadOnly(true);
            }
            //记录从哪里进入信用证卡片
            dataObj.otherParams.cardForm=engineInst.engineParams.cardFrom;
        }
        //传入参数到后台
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType:string,options:any)=>{
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(options:any)=>{
            if('ttPayDetail'==options.detailType){
                options.row.F_PAY_TYPE_NAME = dataObj.refMap.get('F_PAY_TYPE').selected.currentLabel;//设置下拉select选取的文本值到行中
                dataObj.refMap.get('ttPayDetail').setRowData(options.row, options.index);
            }
            return true;
        }
        //删除一行明细之后
        const afterDel=(options:any)=>{
            //删除一行之后，重新结算整个信用证要用到的金额
            let existRows=dataObj.refMap.get('ttPayDetail').getTbInst().tbData;
            dataObj.form.amount=FinanceUtils.gridOrderTotalAmount(existRows);
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法
            let oaApplyDetailData=dataObj.refMap.get('ttPayDetail').getDetailData();
            let result=FinanceUtils.verifySaveData(oaApplyDetailData,proxy);
            if(result)dataObj.form.customerAmountId=dataObj.form.customerAmountId.value;
            return result;
        }
        //保存之后
        const afterSaveHandler=async (res:any)=>{
            //如果保存失败，需要把customerAmountId给找出来，否则会是一串数字，因为在保存之前已被改变
            if(!res.result)dataObj.form.customerAmountId=dataObj.otherParams.customerAmountData.find((item:any)=>item.value==dataObj.form.customerAmountId);
            return true;
        }
        //如果某行已被解锁了，则加删除提示
        const tableRowClassName = (options:any) => {
            options.row.rowIndex = options.rowIndex;
            if (options.row.F_STATUS === 5) return 'warning-row'
            return ''
        }
        //提交
        const submitHandler=async ()=>{
            await dataObj.utilInst.doSubmit();
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,cardDetailParam,beganEdit,beforeEndEdit,beforeOpen,beforeSaveHandler
         ,payMoney,chooseOrder,sureHandler,piDetail,setParam,afterDel,afterSaveHandler,tableRowClassName,submitHandler
        }
    }
});